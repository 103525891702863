



















import {Component, Vue} from 'vue-property-decorator'

@Component({
  metaInfo() {
    return {
      title: 'Ressources',
      meta: [
        { name: 'description', content: "Compilation gratuite de tableurs permettant de générer divers exercices. CE1, CE2, CM1 et CM2." }
      ]
    }
  }
})
export default class Ressources extends Vue {}
